import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Text } from '@opengov/component-library/capital';
import { FigmaEmbed, PatternExample, UsageBlock } from '../../../components';
import LayoutComponents from '../../../components/capital/Layouts/LayoutComponents';
export const _frontmatter = {
  "title": "Text",
  "componentId": "text",
  "description": "Text are used to format content on a page.",
  "activeTab": "components"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutComponents;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Please visit the `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/styles/typography"
        }}>{`Typography`}</a></strong>{` page for more information on using the Text component.`}</p>
    <p><strong parentName="p">{`Documentation in progress------------------------`}</strong></p>
    <h2>{`Configuration`}</h2>
    <h3>{`Font`}</h3>
    <PatternExample example={<Text mdxType="Text">Example</Text>} title="Default" mdxType="PatternExample" />
    <PatternExample example={<Text font="condensed" mdxType="Text">Example</Text>} title="Condensed" mdxType="PatternExample" />
    <h3>{`Size`}</h3>
    <PatternExample example={<Text mdxType="Text">Example</Text>} title="Default" mdxType="PatternExample" />
    <PatternExample example={<Text size="small" mdxType="Text">Example</Text>} title="Small" mdxType="PatternExample" />
    <PatternExample example={<Text size="minimum" mdxType="Text">Example</Text>} title="Minimum" mdxType="PatternExample" />
    <h3>{`Weight`}</h3>
    <PatternExample example={<Text mdxType="Text">Example</Text>} title="Regular" mdxType="PatternExample" />
    <PatternExample example={<Text weight="medium" mdxType="Text">Example</Text>} title="Medium" mdxType="PatternExample" />
    <PatternExample example={<Text weight="bold" mdxType="Text">Example</Text>} title="Bold" mdxType="PatternExample" />
    <h3>{`Color`}</h3>
    <PatternExample example={<Text mdxType="Text">Example</Text>} title="Default" mdxType="PatternExample" />
    <PatternExample example={<Text color="light" mdxType="Text">Example</Text>} title="Light" mdxType="PatternExample" />
    <PatternExample example={<Text color="white" mdxType="Text">Example</Text>} title="White" mdxType="PatternExample" />
    <PatternExample example={<Text color="disabled" mdxType="Text">Example</Text>} title="Disabled" mdxType="PatternExample" />
    <h3>{`HTML Element`}</h3>
    <PatternExample example={<Text as="p" mdxType="Text">Example</Text>} title="Paragraph" mdxType="PatternExample" />
    <PatternExample example={<Text as="div" mdxType="Text">Example</Text>} title="Div" mdxType="PatternExample" />
    <PatternExample example={<Text as="span" mdxType="Text">Example</Text>} title="Span" mdxType="PatternExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      